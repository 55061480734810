import React, { useEffect, useState } from "react"
import ButtonAnchor from "../button-anchor/ButtonAnchor"
import { getDataFromLink, transformText } from "../../utils/text.util";
import "./homeHero.scss"

const HomeHero = (props) => {
  const { title, description, cta, image, imageMobile } = props;
  const [background, setBackground] = useState(image?.url);

  const validateImage = () =>
  setBackground(
      window.innerWidth <= 575.98
        ? imageMobile?.url
        : image?.url
    )

  useEffect(() => {
    validateImage();
    window.addEventListener(`resize`, validateImage);
  }, [])

  const renderCta = () => {
    const ctaDataToConvert = cta?.richText[0];
    if(ctaDataToConvert?.text?.length > 0) {
      const ctaData = getDataFromLink(ctaDataToConvert);

      return (
        <ButtonAnchor
          accent
          {...ctaData?.attributes}>
          {ctaData?.label}
        </ButtonAnchor>
      );
    }
    
    return null;
  }
  
  return (
    <section className="homeHero">
      <div className="homeHero_background" style={{backgroundImage: `url(${background})`}}/>
      <article className="homeHero_content">
        <h1 className="heroTitle">{transformText(title?.richText[0], "heroTitle_accent")}</h1>
        <p className="heroDescription">{description?.richText[0]?.text}</p>
        {renderCta()}
      </article>
    </section>
  )
}

export default HomeHero

import React from "react"

const ClientBox = props => {
  const { name, country, city, logo, url } = props

  function capitalizeFirtsLetter(str) {
    if(str === null) {
      return '';
    }

    if (str === "DOMINICAN_REPUBLIC") {
      return "Republica Dominicana"
    }

    var splitStr = str.toLowerCase().split("_")
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
  }

  return (
    <div className={`client-grid-item fade-in`}>
      <a
        href={url}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="carouselImage" src={logo.url} alt={name}/>
        <p className="carouselText">
          {city}, {capitalizeFirtsLetter(country).replace("_", " ")}
        </p>
      </a>
    </div>
  )
}

const ClientGrid = props => {
  const clients = props.clients

  return (
    <div className="client-grid-container">
      {clients.map(c => (
        <ClientBox
          key={c.key}
          name={c.name}
          country={c.country}
          logo={c.logo}
          city={c.city}
          url={c.homepage.url}
        />
      ))}
    </div>
  )
}

const CarouselContent = props => {
  const { customers, clientsPerPage, carouselPage } = props

  let start = carouselPage * clientsPerPage
  let end = carouselPage * clientsPerPage + clientsPerPage

  return <ClientGrid clients={customers.slice(start, end)} />
}

export default CarouselContent;
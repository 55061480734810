import React from "react"
import HomeSubtitleUnderline from "../home-subtitle-underline/HomeSubtitleUnderline";
import "./homeCollaborators.scss"

const HomeCollaborators = (props) => {
  const { title, partners } = props;

  return (
    <section className="homeCollaborators">
      <HomeSubtitleUnderline upperCase>{title?.richText[0]}</HomeSubtitleUnderline>
      <ul className="homeCollaborators_list">
        {partners?.map((p, index) => (
          <li key={index} className="collaboratorItem">
            <a href={p.partner_homepage.url} target="_blank" rel="noopener noreferrer">
              <img
                src={p.partner_image.url}
                alt={`${p.partner_name} logo`}
                className="collaboratorItem_image"
              />
            </a>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default HomeCollaborators;

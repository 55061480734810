import React, { useRef } from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { getDataFromLink } from "../../utils/text.util";
import HomeSubtitle from "../home-subtitle/HomeSubtitle"
import Anchor from "../anchor/Anchor"
import "swiper/css";
import "swiper/css/pagination";
import "./homeTestimonials.scss"


const HomeTestimonials = (props) => {
  const { category, cta, title, testimonials } = props;
  const ctaData = getDataFromLink(cta?.richText[0]);
  const swiperRef = useRef();
  
  return (
    <section className="homeTestimonials">
      <div className="homeTestimonials_column">
        <HomeSubtitle category={category}>{title?.richText[0]}</HomeSubtitle>
        <Anchor className="testimonialsLink" {...ctaData?.attributes}>{ctaData?.label}</Anchor>
      </div>
      <div className="homeTestimonials_column">
        <Swiper
          className="testimonialsSwiper"
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          pagination={{
            bulletClass: 'swiper-pagination-bullet testimonialsSwiper_bullet'
          }}
          modules={[Pagination]} 
          spaceBetween={50}
          slidesPerView={1}
        >
          {testimonials?.map((item, index) => (
            <SwiperSlide className="testimonialsSwiper_item" key={`testimonialsSwiper_item${index}`}>
              <article className="testimonial">
                <div className="testimonial_content">
                  <p className="comment">{item?.case_description?.text}</p>
                  <div className="detail">
                    <p className="detail_author">{item?.case_author_name}</p>
                    <p className="detail_position">{item?.case_author_position}</p>
                    <p className="detail_place">{item?.case_institution}</p>
                  </div>
                </div>
                <figure
                  className="testimonial_photo"
                  style={{backgroundImage: `url(${item?.case_institution_logo?.url})`}} />
              </article>
            </SwiperSlide>
          ))}
          <div className="testimonialsSwiper_buttons">
            <button
              className="buttonItem"
              onClick={() => swiperRef.current?.slidePrev()}>
              <img className="buttonItem_icon" src="/img/chevron-right-bold.svg" alt="prev" />
            </button>
            <button
              className="buttonItem"
              onClick={() => swiperRef.current?.slideNext()}>
              <img className="buttonItem_icon" src="/img/chevron-right-bold.svg" alt="next" />
            </button>
          </div>
      </Swiper>
      </div>
    </section>
  )
}

export default HomeTestimonials

import React from "react"
import HomeSubtitleUnderline from '../home-subtitle-underline/HomeSubtitleUnderline'
import Carousel from "../carousel/Carousel";
import "./homeClients.scss";

const HomeClients = (props) => {
  const { title, customers } = props;
  const items = customers.map((c, index) => ({
    key: index,
    name: c.customer_name,
    country: c.customer_country,
    city: c.customer_city,
    logo: c.customer_logo,
    homepage: c.customer_homepage,
  }))

  return (
    <section className="homeClients">
      <HomeSubtitleUnderline upperCase>
        {title?.richText[0]}
      </HomeSubtitleUnderline>
      <div className="homeClients_carousel">
        <Carousel items={items} />
      </div>
    </section>
  )
}

export default HomeClients;

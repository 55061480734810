import React from "react"
import HomeSubtitle from "../home-subtitle/HomeSubtitle"
import "./homeDualContentSimple.scss"

const HomeDualContentSimple = (props) => {
  const { data } = props;

  const category = data?.dual_content_simple_category;
  const title = data?.dual_content_simple_title?.richText[0];
  const descriptions = data?.dual_content_simple_description?.richText;

  return (
    <section className="homeDualContentSimple">
      <div className="homeDualContentSimple_column">
        <HomeSubtitle category={category} big>{title}</HomeSubtitle>
      </div>
      <div className="homeDualContentSimple_column">
        <img className="arrow" src="/img/arrow-diagonal.svg" />
        {descriptions.map((item, index) => (
          <p className="description" key={`description-${index}`}>
            {item.text}
          </p>
        ))}
      </div>
    </section>
  )
}

export default HomeDualContentSimple

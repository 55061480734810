import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"

import LanguageContext from "../contexts/language"
import Layout from "../components/layout"
import Header from "../components/header/Header"
import HomeHero from "../components/home-hero/HomeHero"
import HomeDualContentSimple from "../components/home-dual-content-simple/HomeDualContentSimple"
import HomeDualContent from "../components/home-dual-content/HomeDualContent"
import HomeProducts from "../components/home-products/HomeProducts"
import HomeBenefits from "../components/home-benefits/HomeBenefits"
import HomeIntegrations from "../components/home-integrations/HomeIntegrations"
import HomePresence from "../components/home-presence/HomePresence"
import HomeClients from "../components/home-clients/HomeClients"
import HomeTestimonials from "../components/home-testimonials/HomeTestimonials"
import HomeCollaborators from "../components/home-collaborators/HomeCollaborators"
import HomeNews from "../components/home-news/HomeNews"
import FormContact from "../components/form-contact/FormContact"
import Footer from "../components/footer/Footer"
import Seo from "../components/seo"
import '../styles/homePage.scss';

const Homepage = props => {
  const { prismicHomepage, allPrismicNewsArticle } = props.data
  const { data } = prismicHomepage
  const { lang, theme } = useContext(LanguageContext)
  const newsArticles = allPrismicNewsArticle?.nodes;

  const seoByLang = {
    es: {
      title: "foris: Simplificamos los procesos de las universidades", // Headline for schema.org JSONLD
      description:
        "Aplicamos inteligencia artificial para optimizar los procesos críticos de la educación superior.",
    },
    en: {
      title: "foris: We simplify universities processes", // Headline for schema.org JSONLD
      description:
        "We apply artificial intelligence to optimize critical processes in higher education.",
    },
  }
  const seo = seoByLang[lang]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Layout {...props} theme={theme}>
      <Seo title={seo.title} desc={seo.description} />
      <Header />
      <main className="homePage">
        <div className="homePage_upperHalf">
          <HomeHero
            title={data?.hero_title}
            description={data?.hero_description}
            cta={data?.hero_cta}
            image={data?.hero_image}
            imageMobile={data?.hero_image_mobile}
          />
          {data?.body?.length > 0 &&
            data?.body?.map((section, sectionIndex) => {
              switch (section?.slice_type) {
                case "simple_dual_content":
                  return (
                    <HomeDualContentSimple
                      key={`dual_content${sectionIndex}`}
                      data={section?.primary}
                    />
                  )
                case "dual_content":
                  return (
                    <HomeDualContent
                      key={`dual_content${sectionIndex}`}
                      data={section?.primary}
                    />
                  )
                case "products":
                  return (
                    <>
                      <HomeProducts
                        key={`products${sectionIndex}`}
                        title={section?.primary?.products_title}
                        products={section?.items}
                      />
                      <HomeNews
                        category={data?.news_category}
                        title={data?.news_title}
                        articles={newsArticles}
                        more_news_cta_label={data?.more_news_cta_label}
                        newsletter_link={data?.newsletter_link}
                      />
                    </>
                  )
                default:
                  return <></>
              }
          })}
          <HomeIntegrations
            title={data?.integrations_title}
            items={data?.integrations_items}
            description={data?.integrations_description}
          />
          <HomeBenefits
            category={data?.benefits_category}
            title={data?.benefits_title}
            description={data?.benefits_description}
            items={data?.benefits_items}
          />
        </div>
        <div className="homePage_lowerHalf">
          <HomePresence
            image={data?.presence_image}
            title={data?.presence_title}
            numbers={data?.presence_numbers}
          />
          <HomeClients
            title={data?.customers_title}
            customers={data?.customers_list}
          />
          <HomeTestimonials
            category={data?.case_category}
            title={data?.case_title}
            cta={data?.case_cta}
            testimonials={data?.cases}
          />
          <HomeCollaborators 
            title={data.partners_title_2}
            partners={data.partners_list}
          />
          <FormContact
            id="contacto"
            category={data?.contact_category}
            question={data?.contact_question}
            ctaText={data.contact_cta}
            description={data?.contact_description}
            uid="contact-home"
          />
        </div>
      </main> 
      <Footer /> 
    </Layout>
  )
}

export default Homepage

export const pageQuery = graphql`
  query Homepage($uid: String!, $lang: String!) {
    prismicHomepage(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      data {
        hero_title {
          richText
        }
        hero_description {
          richText
        }
        hero_cta {
          richText
        }
        hero_image {
          alt
          url
        }
        hero_image_mobile {
          alt
          url
        }
        body {
          ... on PrismicHomepageDataBodySimpleDualContent {
            id
            slice_type
            primary {
              dual_content_simple_category
              dual_content_simple_title {
                richText
              }
              dual_content_simple_description {
                richText
              }
            }
          }
          ... on PrismicHomepageDataBodyDualContent {
            id
            slice_type
            primary {
              dual_content_category
              dual_content_title_position
              dual_content_title {
                richText
              }
              dual_content_subtitle {
                text
              }
              dual_content_description {
                richText
              }
              dual_content_cta_type
              dual_content_cta {
                richText
              }
              dual_content_image {
                alt
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyProducts {
            id
            slice_type
            primary {
              products_title {
                richText
              }
            }
            items {
              product_background {
                alt
                url
              }
              product_logo {
                alt
                url
              }
              product_description {
                richText
                text
              }
              product_cta_label
              product_cta_url
            }
          }
        }
        integrations_title {
          text
        }
        integrations_items {
          integration_logo {
            url
            alt
          }
        }
        integrations_description {
          text
        }
        benefits_category
        benefits_title {
          richText
        }
        benefits_description {
          richText
        }
        benefits_items {
          benefit_image {
            alt
            url
          }
          benefit_detail {
            richText
            text
          }
        }
        presence_image {
          alt
          url
        }
        presence_title {
          richText
        }
        presence_numbers {
          number_prefix
          number_main
          number_suffix
          number_label
        }
        customers_title {
          richText
        }
        customers_list {
          customer_name
          customer_country
          customer_city
          customer_logo {
            alt
            copyright
            url
          }
          customer_homepage {
            link_type
            url
            target
          }
        }

        partners_title_2 {
          richText
        }
        partners_list {
          partner_name
          partner_homepage {
            url
          }
          partner_image {
            url
          }
          partner_note {
            text
          }
        }

        case_category
        case_title {
          richText
        }
        case_cta {
          richText
        }
        cases {
          case_author_name
          case_author_position
          case_institution
          case_institution_logo {
            alt
            copyright
            url
          }
          case_description {
            html
            text
          }
        }

        news_category
        news_title {
          richText
        }

        contact_category
        contact_question {
          richText
        }
        contact_description {
          richText
        }
        contact_cta
        contact_title {
          html
          text
        }
        more_news_cta_label
        newsletter_link {
          richText
        }
      }
    }
    file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "landings/homepage.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1581, height: 954, formats: PNG)
        fluid {
          src
        }
      }
    }
    allPrismicNewsArticle(
      filter: {data: {article_homepage: {eq: true}}, lang: {eq: $lang}}
      sort: {fields: data___article_homepage_order, order: ASC}
      limit: 4
    ) {
      nodes {
        data {
          article_homepage_order
          article_image {
            url
          }
          article_category
          article_title
          article_description
          article_date
          article_link {
            url
          }

        }
      }
    }
  }
`

import React, {useState} from "react"
import { useMedia } from "react-use-media"
import CarouselContent from "./CarouselContent"
import CarouselButtons from "./CarouselButtons"
import "./carousel.scss";

const Carousel = props => {
  const { items } = props
  const [carouselPage, setCarouselPage] = useState(items[0])
  const isWide = useMedia({
    minWidth: 768,
  })
  const isTablet = useMedia({
    minWidth: 575,
  })

  return (
    <div className="box-grid">
      <CarouselContent
        customers={items}
        carouselPage={carouselPage.key}
        clientsPerPage={isWide ? 8 : isTablet ? 4 : 2}
      />
      <CarouselButtons
        customers={items}
        carouselPage={carouselPage}
        clientsPerPage={isWide ? 8 : isTablet ? 4 : 2}
        setCarouselPage={setCarouselPage}
      />
    </div>
  )
}

export default Carousel;
import React from "react"
import { transformText } from "../../utils/text.util";
import "./homePresence.scss"

const HomePresence = (props) => {
  const { image, title, numbers } = props;
   
  return (
    <section className="homePresence">
      <div className="homePresence_content">
        <figure className="imageBox" style={{ backgroundImage: `url(${image?.url})` }} />
        <div className="textsBox">
          <h3 className="textsBox_title">{transformText(title?.richText[0], "titleAccent")}</h3>
          <div className="textsBox_details">
            {numbers?.map((detail, detailIndex) => (
              <article className="detailItem" key={`detailItem${detailIndex}`}>
                <div className="detailItem_number">
                  {detail?.number_prefix?.length > 0 &&
                    <span className="prefixNumber">{detail?.number_prefix}</span>
                  }
                  <span className="mainNumber">{detail?.number_main}</span>
                  {detail?.number_suffix?.length > 0 &&
                    <span className="suffixNumber">{detail?.number_suffix}</span>
                  }
                </div>
                <p className="detailItem_label">{detail?.number_label}</p>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomePresence

import React from "react"

const Pager = (props) => {
  const {state, direction, setCarouselPage} = props;

  return (
    <div className={`box-grid-${direction}-pager`}>
      <button
        className={`pagerButton box-page-link ${
          direction === "prev" ? "carousel-control-prev" : ""
        }`}
        style={{
          border: 0,
          background: "none",
          outline: "none",
          transform: direction === "next" ? "" : "scaleX(-1)",
        }}
        onClick={() => setCarouselPage(state)}
      >
        {direction === "next" ? (
          <img className="pagerButton_icon" src="/img/chevron-right-bold.svg" alt="next" />
        ) : (
          <img className="pagerButton_icon" src="/img/chevron-right-bold.svg" alt="back" />
        )}
      </button>
    </div>
  )
}

const CarouselButtons = (props) => {
  const { customers, carouselPage, clientsPerPage, setCarouselPage } = props
  const totalPages = Math.ceil(customers.length / clientsPerPage)

  return (
    <>
      <Pager state={customers[(carouselPage.key + 1) % totalPages]} direction="next" setCarouselPage={setCarouselPage}></Pager>
      <Pager state={customers[(carouselPage.key - 1 + totalPages) % totalPages]} direction="prev" setCarouselPage={setCarouselPage}></Pager>
    </>
  )
}

export default CarouselButtons;


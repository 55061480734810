import React, { useContext } from "react"
import HomeSubtitleUnderline from "../home-subtitle-underline/HomeSubtitleUnderline";
import ButtonAnchor from "../button-anchor/ButtonAnchor"
import LanguageContext from "../../contexts/language"
import { transformText } from "../../utils/text.util";
import "./homeProducts.scss"

const HomeProducts = (props) => {
  const { title, products } = props;
  const { urlPrefix } = useContext(LanguageContext)

  const renderCta = (product) => {
    const linkTo = `${urlPrefix}${product?.product_cta_url}`
    return (
      <ButtonAnchor
        className="productButton"
        href={linkTo}
      >
        {product?.product_cta_label}
      </ButtonAnchor>
    )
  }
  
  return (
    <section className="homeProducts">
      <HomeSubtitleUnderline>{title?.richText[0]}</HomeSubtitleUnderline>
      <div className="homeProducts_items">
        {products?.map((product, productIndex) => (
          <article className="productCard" key={`productCard${productIndex}`}>
            <figure className="productCard_header" style={{backgroundImage: `url(${product?.product_background?.url})`}}>
              <img className="productLogo" src={product?.product_logo?.url} alt={product?.product_logo?.alt} />
            </figure>
            <div className="productCard_body">
              <p className="productDescription">{transformText(product?.product_description?.richText[0], "productDescription_strong")}</p>
              {renderCta(product)}
            </div>
          </article>
        ))}
      </div>
    </section>
  )
}

export default HomeProducts

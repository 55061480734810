import React from "react"
import HomeSubtitleUnderline from "../home-subtitle-underline/HomeSubtitleUnderline"
import "./homeIntegrations.scss"

const HomeIntegrations = (props) => {
  const {title, items, description} = props;

  return (
    <section className="homeIntegrations">
      <div className="homeIntegrations_box">
        <HomeSubtitleUnderline left withoutTransform>{title?.text}</HomeSubtitleUnderline>
        <p className="integrationDescription">
          {description?.text}
        </p>
      </div>
      <div className="homeIntegrations_items">
        {items?.map((brand, brandIndex) => (
          <figure className="integrationItem" key={`integrationItem${brandIndex}`}>
            <img
              className="integrationItem_logo"
              src={brand?.integration_logo?.url}
              alt={brand?.integration_logo?.alt}
            />
          </figure>
        ))}
      </div>
    </section>
  )
}

export default HomeIntegrations

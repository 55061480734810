import React, { useEffect, useState } from "react"
import cx from "classnames"
import "./homeNewsArticle.scss"

const HomeNewsArticle = ({article}) => {
  const isMain = article?.article_homepage_order === 3;
  const [isDesktop, setIsDesktop] = useState(true);

  const validateWidth = () => setIsDesktop(window.innerWidth > 575.98)

  useEffect(() => {
    validateWidth();
    window.addEventListener(`resize`, validateWidth);
  }, [])
  
  return (
    <a
      className={cx("homeNewsArticle", (isMain && isDesktop) && "homeNewsArticle__tall" )}
      style={{backgroundImage: (isMain && isDesktop) ? `url(${article?.article_image?.url})` : 'none'}}
      href={article?.article_link?.url}
      target="_blank"
      rel="noreferrer">
      <label className="homeNewsArticle_category">{article?.article_category}</label>
      <h6 className="homeNewsArticle_title">{article?.article_title}</h6>
      <p className="homeNewsArticle_description">{article?.article_description}</p>
      <div className="homeNewsArticle_details">
        <span className="articleDate">{article?.article_date}</span>
        <figure className="articleChevron"/>
      </div>
    </a>
  )
}

export default HomeNewsArticle
